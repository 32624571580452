<template>
  <div class="product-page--create">
    <v-row no-gutters>
      <v-col cols="12">
        <redirect class="mb-5" title="Upsell offers" route="website.apps.bundle.list" />
        <div class="page-header">
          <h2 class="page-title">Create bundle</h2>
        </div>
      </v-col>
    </v-row>
    <v-col cols="12">
      <Notification type="error" :messages="errors" v-if="errors.length > 0" />
    </v-col>
    <div>
      <v-row>
        <v-col cols="12" sm="4">
          <h4>Offer's name & message</h4>
        </v-col>
        <!-- <pre>{{ formData }}</pre> -->
        <v-col cols="12" sm="8" class="elevation-form">
          <InputDefault label="Offer's name" :model="['formData', 'offerName']" />
          <small class="input-desc mt-2">
            Give your offer a name to manage easily or leave blank and we will automatically generate a name for you
          </small>
          <InputDefault
            :itemsSelect="itemsMessage"
            typeComponent="select"
            label="Offer message"
            :model="['formData', 'offerMessage']"
          />
        </v-col>
      </v-row>
      <v-col cols="12" class="my-2">
        <v-divider class=""></v-divider>
      </v-col>
      <v-row>
        <v-col cols="12" sm="4">
          <h4>Choose Bundle products</h4>
          <small>Motivate customers to add this bundle to cart when they visit any of the bundled products </small>
        </v-col>
        <v-col cols="12" sm="8" class="elevation-form">
          <div>
            <span v-if="formData.targetIds">{{ formData.targetIds.length }} product selected</span>
            <span v-else>No product selected </span>
          </div>

          <v-btn
            small
            elevation=""
            color="primary"
            @click="configDialogSelection({ type: 'products', model: 'targetIds' })"
            >Select products</v-btn
          >

          <v-divider class="mt-3"></v-divider>
          <small class="input-desc">
            Pro tip: According to our statistics, bundle of 2 or 3 products see over 20% conversion
          </small>
          <v-checkbox label="Only show this bundle when customers visit target"> </v-checkbox>
        </v-col>
      </v-row>
      <v-col cols="12" class="my-2">
        <v-divider class=""></v-divider>
      </v-col>

      <v-row>
        <v-col cols="12" sm="4">
          <h4 class="d-flex align-center">Offer’s discount <v-switch /></h4>
          <small class="input-desc mt-2">
            Offer discount and scarcity to motivate customers add Up-sell products to cart
          </small>
        </v-col>
        <v-col cols="12" sm="8" class="elevation-form">
          <InputDefault
            :sufix="currency.currencyCode()"
            label="Discount percentage"
            :model="['formData', 'percentageDiscount']"
          />
        </v-col>

        <v-col cols="12" class="d-flex">
          <v-spacer></v-spacer>
          <v-btn outlined class="mr-3" @click="onCancel">Discard</v-btn>
          <v-btn :loading="isLoading" color="primary" @click="onSubmit">Create</v-btn>
        </v-col>
      </v-row>
    </div>
    <v-col cols="12" class="my-3">
      <v-divider class=""></v-divider>
    </v-col>
    <v-dialog :scrollable="false" v-model="dialog" width="900" content-class="l-dialog">
      <SelectionItems
        :isIconAddAll="false"
        :isIconRemoveAll="false"
        :model="configSelectionItem.model"
        :type="configSelectionItem.type"
        @returnData="getDataSelected"
        :dialog.sync="dialog"
      />
    </v-dialog>
    <BeforeLeavePage />
  </div>
</template>

<script>
import SelectionItems from '../../component/SelectionItems';
import { offerApi } from '@/apis/offers';
import currency from '@/helpers/currency';
export default {
  components: {
    SelectionItems,
  },
  data() {
    return {
      currency,
      errors: [],
      configSelectionItem: {
        type: 'products',
        model: 'targetIds',
      },
      dialog: false,
      isLoading: false,
      typeTarget: 'all',
      itemsMessage: [
        {
          name: 'Frequently bought with {{product name}}',
          id: 'Frequently bought with {{product name}}',
        },
        {
          name: 'Complete your order with {{product name}}',
          id: 'Complete your order with {{product name}}',
        },
        {
          name: 'Best deal today for lucky customers like you',
          id: 'Best deal today for lucky customers like you',
        },
      ],
      formData: {
        activated: true,
        offerMessage: 'Complete your order with {{product name}}',
        offerType: 'prePurchase',
        priority: 0,
        offerName: '',
        offerTitle: null,
        recommendIds: [], // product collection ids ,
        recommendType: '', //product, collection, sameCollection, relevantCollection
        targetIds: [],
        targetType: 'product', //all, product, collection
        store: '',
        enableDiscount: false,
        percentageDiscount: 0,
      },
    };
  },
  watch: {
    // formData: {
    //   handler: function() {
    //     if (this.formData.targetType === 'all') {
    //       this.formData.targetIds = [];
    //     }
    //     if (this.formData.recommendType === 'relevantCollection' || this.formData.recommendType === 'sameCollection') {
    //       this.formData.recommendIds = [];
    //     }
    //   },
    //   deep: true,
    // },
    'formData.targetType': function() {
      this.formData.targetIds = [];
    },
    'formData.recommendType': function() {
      this.formData.recommendIds = [];
    },
  },
  methods: {
    onCancel() {},
    async onSubmit() {
      let convertData = Object.assign({}, this.formData);
      convertData.targetIds = convertData.targetIds.map(item => item._id);
      convertData.recommendIds = convertData.recommendIds.map(item => item._id);
      convertData.store = '601e3a3004045e678f96819e';
      try {
        let response = await offerApi.create({ offer: convertData });
        console.log('response', response);
      } catch (error) {
        console.log(error);
        //empty
      }
    },
    configDialogSelection(data) {
      this.dialog = true;
      this.configSelectionItem = Object.assign({}, data);
    },
    getDataSelected(data, model) {
      console.log('data', data);
      this.formData[model] = JSON.parse(JSON.stringify(data));
      console.log('this.formData', this.formData);
    },
  },
};
</script>

<style lang="scss" scoped></style>
